import React from 'react';
import ReactDOM, {Root} from 'react-dom/client';
import './index.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {MaintenancePage} from "./MaintenancePage";

const root: Root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/*" element={<MaintenancePage />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);
