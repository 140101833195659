import React from 'react';
import {Timer, Mail, Github} from 'lucide-react';
import './MaintenancePage.css';

export const MaintenancePage: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="maintenance-container">
            <div className="maintenance-card">
                <div className="header">
                    <Timer className="timer-icon"/>
                    <h1>Site en Maintenance</h1>
                    <p className="subtitle">Mon portfolio fait peau neuve !</p>
                </div>

                <div className="main-message">
                    <p>
                        Je travaille actuellement sur une nouvelle version de mon portfolio pour vous offrir une
                        meilleure expérience.
                    </p>
                    <p>
                        Le site sera de retour très prochainement avec un nouveau design et de nouveaux projets !
                    </p>
                </div>

                <div className="contact-section">
                    <h2>Restons en contact</h2>
                    <div className="social-links">
                        <a
                            href="mailto:flash303mc@gmail.com"
                            className="social-link"
                            aria-label="Email"
                        >
                            <Mail/>
                        </a>
                        <a
                            href="https://github.com/Flash303"
                            className="social-link"
                            aria-label="GitHub"
                        >
                            <Github/>
                        </a>
                    </div>
                </div>

                <div className="progress-section">
                    <div className="progress-bar">
                        <div className="progress-fill"></div>
                    </div>
                    <p className="progress-text">Progression estimée : 75%</p>
                </div>

                <footer className="footer">
                    <p>© {currentYear} - Tous droits réservés</p>
                </footer>
            </div>
        </div>
    )
};